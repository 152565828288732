<script setup lang="ts">
const currentYear = new Date().getFullYear()
const regionState = useRegion()
const runtimeConfig = useRuntimeConfig()
</script>
<template>
  <div
    class="footer w-full rounded-t-[1.875rem] bg-primary px-[1.5rem] py-[3rem] text-white md:px-0 md:py-[5rem]"
  >
    <div class="mx-auto w-[90vw]">
      <div class="flex flex-col gap-[1.88rem] overflow-x-hidden md:gap-[5rem] lg:flex-row">
        <div
          class="flex w-full flex-col justify-between gap-[1.25rem] md:min-h-[17.125rem] md:w-[20rem]"
        >
          <div class="flex flex-col gap-[1.25rem]">
            <NuxtLinkLocale to="/">
              <div class="w-full md:w-[13.75rem]">
                <nuxt-picture
                  format="webp"
                  src="/images/tja_logo_dark.png"
                  alt="TechJobAsia Logo (Dark)"
                  width="220"
                  height="59"
                  :preload="false"
                />
              </div>
            </NuxtLinkLocale>
            <div class="text-t5 font-light">
              {{ runtimeConfig.public.siteName }} @ {{ currentYear }}
              <div v-if="regionState" class="text-t5 font-light">
                Hong Kong EA License No. 77101
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center gap-[1.25rem]">
            <div class="flex h-[5rem] w-[5rem] items-center">
              <NuxtPicture
                format="webp"
                src="/images/awards/gcp partner.png"
                alt="Google Cloud Partner Logo (TalentLabs / TechJobAsia)"
                width="240"
                height="240"
                fit="cover"
                background="transparent"
                :preload="false"
              />
            </div>
            <div class="flex h-[4.375rem] w-[5.54167rem] items-center">
              <NuxtPicture
                format="webp"
                src="/images/awards/aws partner.png"
                alt="AWS Partner Logo (TalentLabs / TechJobAsia)"
                width="266"
                height="210"
                fit="cover"
                background="transparent"
                :preload="false"
              />
            </div>
            <div class="flex h-[5rem] w-[6.782rem] items-center">
              <NuxtPicture
                format="webp"
                src="/images/awards/hkict award 2023.png"
                alt="Hong Kong ICT Award 2023 Gold Award (TalentLabs / TechJobAsia)"
                width="3470"
                height="2396"
                background="transparent"
                :preload="false"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-[1.88rem] md:flex-row md:gap-[3rem]">
          <div class="flex flex-col gap-[1.88rem] lg:min-w-[8.7rem]">
            <div class="text-h4 font-medium">{{ $t('jobBoard.footer.jobSeeker') }}</div>
            <NuxtLinkLocale to="/jobs">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.searchJobs') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/sitemap/job-campaigns">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.jobCampaigns') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/sitemap/jobs">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.allJobs') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/sitemap/companies">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.allCompanies') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/sitemap/popular-searches">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.popularSearches') }}
              </div>
            </NuxtLinkLocale>
          </div>
          <div class="flex flex-col gap-[1.88rem] lg:min-w-[8.7rem]">
            <div class="text-h4 font-medium">{{ $t('jobBoard.footer.employer') }}</div>
            <NuxtLinkLocale to="/employer">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.recruiterLandingPage.servicesPricing') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/auth/login?role=employer">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.startHiring') }}
              </div>
            </NuxtLinkLocale>
            <a
              class="text-t5 font-light hover:underline"
              href="mailto:info@techjobasia.com"
              target="_blank"
            >
              {{ $t('jobBoard.footer.contactUs') }}
            </a>
          </div>
          <div class="flex flex-col gap-[1.88rem] lg:min-w-[8.7rem]">
            <div class="text-h4 font-medium">{{ $t('jobBoard.footer.newsAndInsights') }}</div>
            <NuxtLinkLocale to="/articles">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.newsAndInsights') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/articles/categories/19---時事新聞熱話">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.hotTopics') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/articles/categories/20---生活副刋趣聞">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.funFacts') }}
              </div>
            </NuxtLinkLocale>
          </div>
          <div class="flex flex-col gap-[1.88rem] lg:min-w-[8.7rem]">
            <div class="text-h4 font-medium">{{ $t('jobBoard.footer.partneredPrograms') }}</div>
            <NuxtLinkLocale to="https://moses.talentlabs.org/" target="_blank">
              <div class="text-t5 font-light hover:underline">Project Moses with 香港博愛</div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="https://gcc.talentlabs.org/" target="_blank">
              <div class="text-t5 font-light hover:underline">Google Career Certificates</div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="https://kydp.talentlabs.org/" target="_blank">
              <div class="text-t5 font-light hover:underline">K-Youth with Khazanah Nasional</div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="https://www.techjobasia.com/hk-events/ysip" target="_blank">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.ccf2025.ysip') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale
              to="https://www.techjobasia.com/hk-events/Cyberport-Career-Fair-2025"
              target="_blank"
            >
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.ccf2025.ccf2025') }}
              </div>
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col gap-[1.88rem] overflow-x-hidden md:gap-[5rem] lg:mt-[3rem] lg:flex-row"
      >
        <div class="flex w-full flex-col justify-between gap-[1.25rem] md:w-[20rem]"></div>
        <div class="flex flex-col gap-[1.88rem] md:flex-row md:gap-[3rem]">
          <div class="flex flex-col gap-[1.88rem] lg:min-w-[8.7rem]">
            <div class="text-h4 font-medium">{{ $t('jobBoard.footer.moreAboutUs') }}</div>
            <NuxtLinkLocale to="/about">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.aboutUs') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/privacy-policy">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.privacyPolicy') }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/terms">
              <div class="text-t5 font-light hover:underline">
                {{ $t('jobBoard.footer.termsAndConditions') }}
              </div>
            </NuxtLinkLocale>
          </div>
          <div class="flex flex-col gap-[1.88rem] lg:min-w-[8.7rem]">
            <div class="text-h4 font-medium">{{ $t('jobBoard.footer.products') }}</div>
            <NuxtLinkLocale to="https://www.talentlabs.org" target="_blank">
              <div class="text-t5 font-light hover:underline">TalentLabs</div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/">
              <div class="text-t5 font-light hover:underline">
                {{ runtimeConfig.public.siteName }}
              </div>
            </NuxtLinkLocale>
            <NuxtLinkLocale to="https://www.bridgespace.com/" target="_blank">
              <div class="text-t5 font-light hover:underline">Bridgespace</div>
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
      <div class="mt-[1.88rem] flex w-full flex-col justify-end gap-[1.25rem] md:flex-row">
        <!-- <div v-if="runtimeConfig.public.environmentName !== 'prod'" class="w-full md:w-[9.375rem]">
          <RegionSwicher open-direction="UPWARDS" />
        </div> -->
        <div class="w-full md:w-[9.37rem]">
          <LangSwitcher open-direction="UPWARDS" />
        </div>
      </div>
    </div>
  </div>
</template>
